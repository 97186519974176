import React from "react"
import {withRouter} from "react-router-dom"
import Map from "../map"
import defaultCover from "../../../../assets/images/property-tour-placeholder.png"
import copyImage from "../../../../assets/images/propertyTour/copy.svg"
import bedroomImage from "../../../../assets/images/propertyTour/bedroom.svg"
import bathroomImage from "../../../../assets/images/propertyTour/bathroom.svg"
import lotSizeImage from "../../../../assets/images/propertyTour/lot-size.svg"
import sqFeetImage from "../../../../assets/images/propertyTour/sq-feet.svg"
import logo from "../../../../assets/images/white-logo.svg"
import downloadIcon from "../../../../assets/images/icons/download.svg"
import FooterSection from "../pageComponents/FooterSection"
import {fetchBinaryData, fetchPropertyTourProject, fetchPropertyTourProjectMedias} from "../../util/project_api_util"
import PropertyTourNavbarContainer from "./property_tour_navbar"
import {InView} from 'react-intersection-observer';
import Gallery from "./gallery";
import FloorPlans from "./floor_plans";
import PropertyTourContactForm from "./property_tour_contact_form";
import {downloadMediaFolder} from "../../util/file_utils";
import FadeIn from "./fade_in";

class PropertyTourMain extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      project: null,
      media: {},
      downloadingFloorPlans: false,
      contentAppeared: false
    }

    this.videoRef = React.createRef()
    this.changeVideoState = this.changeVideoState.bind(this)
    this.downloadFloorPlans = this.downloadFloorPlans.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const projectId = this.props.match.params.id

    setTimeout(() => {
      this.setState({contentAppeared: true})
    }, 500)

    fetchPropertyTourProject(projectId).then(async result => {
      const project = Object.values(result)[0]

      project.services.forEach(service => {
        fetchPropertyTourProjectMedias(project.id, service.id).then(result => {
          const resultProject = result[project.id]
          this.setState({media: {...this.state.media, [service.slug]: resultProject?.sorted_medias || []}})
        })
      })

      this.setState({project})
    })
  }

  changeVideoState(inView) {
    if (inView) {
      this.videoRef.current.play();
    } else {
      this.videoRef.current.pause();
    }
  }

  copyToClipboard(text) {
    navigator.clipboard.writeText(text);
    this.props.showToast("Address has been copied to clipboard");
  }

  downloadFloorPlans(e) {
    e.preventDefault()

    const floorPlansMediaIds = this.state.media.floorplans.map(media => media.id)

    this.setState({downloadingFloorPlans: true})

    fetchBinaryData(floorPlansMediaIds, this.state.project.id).then(medias => {
      return downloadMediaFolder(medias)
    }).then(() => {
      this.setState({downloadingFloorPlans: false})
    }).catch(() => {
      this.setState({downloadingFloorPlans: false})
    })
  }

  allProjectServicesMediaTypesBySlug() {
    const {project} = this.state
    if (!project) return {}

    const projectServicesBySlug = project.services.reduce((res, s) => {
      res[s.slug] = s.media_types
      return res
    }, {})

    const externalPhotoServicesBySlug = project.external_photos.reduce((res, p) => {
      res[p.service_slug] = p.service_media_types
      return res
    }, {})

    return {...projectServicesBySlug, ...externalPhotoServicesBySlug}
  }

  medias(slugs) {
    const {project, media} = this.state

    const projectMedias = slugs.reduce((res, slug) => {
      return [...res, ...(media[slug] || [])]
    }, []).filter(media => {
      return media.hidden === false
    })

    const externalMedias = (project?.external_photos || []).filter(external => {
      return slugs.includes(external.service_slug)
    })

    return [...projectMedias, ...externalMedias]
  }

  render() {
    const {currentUser, branded} = this.props
    const {project, media} = this.state
    const address = project?.address?.formatted_address ? `${project?.address?.apartment_number || ""} ${project?.address?.formatted_address}` : `${project?.address?.street1}${project?.address?.apartment_number ? ", " : ""}${project?.address?.apartment_number || ""} ${project?.address?.city}, ${project?.address?.region} ${project?.address?.zip_code}`

    const allProjectServiceMediaTypesBySlug = this.allProjectServicesMediaTypesBySlug()
    const allProjectServiceSlugs = Object.keys(allProjectServiceMediaTypesBySlug)
    const allProjectImageServiceSlugs = allProjectServiceSlugs
      .filter(slug => allProjectServiceMediaTypesBySlug[slug] === 'image')
      .filter(slug => slug !== 'floorplans')

    const sortedMedias = this.medias(allProjectImageServiceSlugs)
    const floorplansMedias = this.medias(['floorplans'])

    return <div className="propertyTourPage">
      <nav className={`react-navbar-property-tour ${this.state.contentAppeared ? 'show' : ''}`}>
        <PropertyTourNavbarContainer has3DTour={!!project?.external_model_id}
                                     hasGallery={sortedMedias.length > 0}
                                     hasFloorplan={floorplansMedias.length > 0}
                                     project={project}
                                     branded={branded}
                                     currentUser={currentUser}/>
      </nav>

      {project ? (
        <div className="propertyTourMainDiv">
          <div className={`propertyTourCoverImage ${this.state.contentAppeared ? 'shrink' : ''}`}>
            <img src={project.property_tour_cover || defaultCover}/>

            {!project.property_tour_cover && <div className="propertyTourCoverImageButton">
              <img src={logo} className="propertyTourCoverImageLogo"/>
              <a className="projectEditButton projectEditButtonGrey projectEditButtonLarge"
                 href={`/${project.slug}/listing-website/edit`}>
                Update details and images
              </a>
            </div>}
          </div>
          <div className={`propertyTourMainContentGroup ${this.state.contentAppeared ? 'show' : ''}`} id="details">
            <FadeIn>
              <div className="propertyTourAddressGroup">
                <div className="propertyTourPrice">
                  ${project.price ? parseFloat(project.price).toLocaleString() : '0'}
                </div>
                <div className="propertyTourAddress">
                  <img src={copyImage} className="propertyTourAddressCopy"
                       onClick={() => this.copyToClipboard(address)}/>
                  {address}
                </div>
              </div>
              <div className="propertyTourDescriptionGroup">
                <div className="detailMainGroup">
                  <img src={lotSizeImage}/>
                  <div className="detailMainGroupTitle">
                    {project.lot_size || 0}
                  </div>
                  <div className="detailMainGroupDescription">Lot Size</div>
                </div>
                <div className="detailMainGroup">
                  <img src={bedroomImage}/>
                  <div className="detailMainGroupTitle">
                    {project.beds || 0}
                  </div>
                  <div className="detailMainGroupDescription">{project.beds !== 1 ? "Bedrooms" : "Bedroom"}</div>
                </div>
                <div className="detailMainGroup">
                  <img src={bathroomImage}/>
                  <div className="detailMainGroupTitle">
                    {project.baths ? project.baths : 0}
                  </div>
                  <div className="detailMainGroupDescription">{project.baths !== 1 ? "Bathrooms" : "Bathroom"}</div>
                </div>
                <div className="detailMainGroup">
                  <img src={sqFeetImage}/>
                  <div className="detailMainGroupTitle">
                    {project.square_feet || 0}
                  </div>
                  <div className="detailMainGroupDescription">Square Feet</div>
                </div>
              </div>
              <div className={`propertyDescription text-pre-wrap ${branded ? '' : 'mb-60'}`}>
                {project.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
              </div>
              {branded && <a className="agentButton" href="#contact">
                Get Agent Info
              </a>}
            </FadeIn>
          </div>

          {sortedMedias.length > 0 && <div className="propertyTourSection propertyTourSectionBlack" id="gallery">
            <FadeIn>
              <h2 className="propertyTourSectionTitle light">Gallery</h2>
              <div className="propertyTourContainer">
                <Gallery images={sortedMedias}/>
              </div>
            </FadeIn>
          </div>}

          {floorplansMedias.length > 0 && <div className="propertyTourSection" id="floorplan">
            <FadeIn>
              <h2 className="propertyTourSectionTitle dark">Floor Plans</h2>

              <div className="downloadButton">
                <button className="projectEditButton projectEditButtonLight"
                        onClick={this.downloadFloorPlans}
                        disabled={this.state.downloadingFloorPlans}>
                  <img src={downloadIcon}/>
                  Download Floorplans
                </button>
              </div>

              <div className="propertyTourContainer">
                <FloorPlans images={floorplansMedias}/>
              </div>
            </FadeIn>
          </div>}

          {project.external_model_id &&
            <div className="propertyTourSection propertyTourSectionBlack propertyVirtualTour" id="3d-tour">
              <FadeIn>
                <h2 className="propertyTourSectionTitle light">Virtual Tour</h2>
                <div className="propertyTourContainer">
                  <iframe className="w-100" src={project.external_model_id}/>
                </div>
              </FadeIn>
            </div>}

          {project.external_video_id &&
            <div className="propertyTourSection propertyTourSectionBlue propertyTourSectionVideo">
              <FadeIn>
                <h2 className="propertyTourSectionTitle dark">Video Tour</h2>
                <div className="propertyTourContainer">
                  <InView as="div" onChange={(inView) => this.changeVideoState(inView)}>
                    <video controls muted preload="metadata" playsInline ref={this.videoRef}>
                      <source src={`${project.external_video_id}`} type="video/mp4"/>
                    </video>
                  </InView>
                </div>
              </FadeIn>
            </div>}

          <div className="propertyTourSection propertyTourSectionLocation" id="map">
            <FadeIn>
              <h2 className="propertyTourSectionTitle dark">Location</h2>

              <div className="propertyTourContainer">
                <div className="text-center">
                  <div className="propertyTourAddress">
                    <img src={copyImage} className="propertyTourAddressCopy"
                         onClick={() => this.copyToClipboard(address)}/>
                    {address}
                  </div>
                </div>

                <div className="propertyTourMap">
                  <Map projects={[project]}/>
                </div>
              </div>
            </FadeIn>
          </div>

          {branded && <div className="propertyTourSection propertyTourSectionBlue" id="contact">
            <FadeIn>
              <h2 className="propertyTourSectionTitle dark">Contact</h2>

              <div className="propertyTourContainer propertyTourContactFormContainer">
                <div className="propertyTourContactForm" id="agent">
                  <div>
                    <div className="agentInfo">
                      <div className="agentLogo">
                        <img src={project?.client?.avatar}/>
                      </div>
                      <div className="agentNameAndPosition">
                        <div className="agentName">{project.client.first_name} {project.client.last_name}</div>
                        {project.client.company_name &&
                          <div className="agentPosition">{project.client.company_name}</div>}

                        <div className="agentDivider"/>

                        {project.client?.phone_number && <div className="agentContact">
                          <i className="fas fa-phone bg-opacity-50"/>
                          <a href={`tel:${project.client.phone_number}`}>{project.client.phone_number}</a>
                        </div>}

                        {project.client?.email && <div className="agentContact">
                          <i className="fas fa-envelope bg-opacity-50"/>
                          <a href={`mailto:${project.client.email}`}>{project.client.email}</a>
                        </div>}
                      </div>
                    </div>
                    <div className="agentForm">
                      <h3 className="agentFormTitle">We’ll get back to you</h3>

                      <PropertyTourContactForm project={project}/>
                    </div>
                  </div>
                </div>
              </div>
            </FadeIn>
          </div>}

          <FooterSection displayLoginButtons={!currentUser} displayOrderNow={false}/>
        </div>
      ) : <div/>}
    </div>
  }
}

export default withRouter(PropertyTourMain)