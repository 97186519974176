import React from "react"
import {withRouter} from "react-router-dom"
import UploadPhotosModal from "./upload_photos_modal_container"
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import GalleryModal from "../gallery/gallery_modal"
import video from "../../../../assets/images/icons/service/VideoService.svg"
import arrowDownSrc from "../../../../assets/images/icons/blue-arrow-down.svg"
import mediaDownloadIconSrc from '../../../../assets/images/icons/media-download-icon.svg'
import {ReactComponent as DownloadIcon} from '../../../../assets/images/icons/download.svg'
import {
  deleteMedia,
  fetchBinaryData,
  fetchProjectMedias,
  updateProjectDetails,
  uploadPhotos
} from "../../util/project_api_util"
import DownloadModal from "./download_modal_container";
import { serviceIcon } from "../../util/service_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { InView } from 'react-intersection-observer';
import {downloadMediaFolder} from "../../util/file_utils";
import ShareMediaModal from "./share_media_modal_container"

class EditProjectMedia extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      photoSelection: false,
      streamAmount: this.props.isLargeScreen ? 12 : 8,
      selectedMedia: {},
      modalOpen: false,
      galleryModal: false,
      virtualStagingGalleryModal: false,
      shareMediaModal: false,
      virtualStagingPhoto: {},
      downloadModal: false,
      manualUpload: false,
      currentPhoto: 0,
      unprocessed: this.props.currentUser?.roles?.admin || this.props.currentUser?.roles?.post_production || this.props.currentUser?.roles?.contractor || (this.props.project?.upload_key && this.props.project?.upload_key === this.props.match.params.accessCode) ? true : false,
      copied: {},
      copiedLink: false,
      copiedSnippet: false,
      selectedServiceIndex: 0,
      upload_link: this.props.project?.external_model_id,
      processedMedias: Object.values(this.props.project?.processed_medias || {}),
      unprocessedMedias: Object.values(this.props.project?.unprocessed_medias || {}),
      unprocessed_zip_worker_file: this.props.project?.unprocessed_zip_worker_file,
      processed_zip_worker_file: this.props.project?.processed_zip_worker_file,
      uploadDisabled: true,
      success_message: "",
      download_pending: false,
      loading: false,
      swapStatus: {},
    }
    this.handler = this.handler.bind(this)
    this.onDeleteMedia = this.onDeleteMedia.bind(this)
    this.downloadMedia = this.downloadMedia.bind(this)
    this.downloadVirtualMedia = this.downloadVirtualMedia.bind(this)
    this.getDownloadInfo = this.getDownloadInfo.bind(this)
    this.selectAllMedia = this.selectAllMedia.bind(this)
    this.enableSelection = this.enableSelection.bind(this)
    this.disableSelection = this.disableSelection.bind(this)
    this.clearSelection = this.clearSelection.bind(this)
    this.openUploadModal = this.openUploadModal.bind(this)
    this.selectMedia = this.selectMedia.bind(this)
    this.copyEmbeddedCode = this.copyEmbeddedCode.bind(this)
    this.downloadPhoto = this.downloadPhoto.bind(this)
    this.onClose = this.onClose.bind(this)
    this.submitMatterport = this.submitMatterport.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.copyLink = this.copyLink.bind(this)
    this.copySnippet = this.copySnippet.bind(this)
    this.openInNewTab = this.openInNewTab.bind(this)
    this.downloadAll = this.downloadAll.bind(this)
    this.reloadProjectMedias = this.reloadProjectMedias.bind(this)
    this.swapProductionStatus = this.swapProductionStatus.bind(this)
    this.changeVideoState = this.changeVideoState.bind(this)
    this.videoRef = React.createRef()
    this.openVirtualStagingGallery = this.openVirtualStagingGallery.bind(this)
    this.submitPhotosFromUploadModal = this.submitPhotosFromUploadModal.bind(this)
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps?.project?.current_service?.id != this.props?.project?.current_service?.id){
      this.reloadProjectMedias(this.props.project?.current_service?.id)
    }
  }

  handler(newState) {
    this.setState(newState)
  }

  changeVideoState(inView) {
    if (inView) {
      this.videoRef.current.play();
    } else {
      this.videoRef.current.pause();
    }
  }

  reloadProjectMedias(serviceId, ignore_variants = null) {
    const projectId = this.props.project.id
    const access_code = this.props.match.params?.accessCode
    const media_code = this.props.match.params?.shareCode

    this.setState({loading: true})

    return fetchProjectMedias(projectId, serviceId, media_code, access_code, ignore_variants).then((response) => {
      const projectMedias = Object.values(response)[0]

      this.setState({
        processedMedias: Object.values(projectMedias.processed_medias || {}),
        unprocessedMedias: Object.values(projectMedias.unprocessed_medias || {}),
        unprocessed_zip_worker_file: projectMedias.unprocessed_zip_worker_file,
        processed_zip_worker_file: projectMedias.processed_zip_worker_file,
        loading: false
      });
    })
  }

  swapCubicasa(package_id, cubicasa){
    let data = {id: this.props.project.id, project: {id: this.props.project.id, cubicasa: cubicasa, package_id: package_id}}
    updateProjectDetails(data).then(this.props.reloadProject())
  }

  swapProductionStatus(current_package, production_downloaded){
    if(Object.values(this.state.swapStatus).length === 0){
      let new_package = current_package
      new_package["production_downloaded"] = production_downloaded
      let data = { id: this.props.project.id, project: { id: this.props.project.id, packages_attributes: [new_package] } }
      let oldSwapStatus = JSON.parse(JSON.stringify(this.state.swapStatus))
      let newSwapStatus = JSON.parse(JSON.stringify(this.state.swapStatus))
      newSwapStatus[current_package.service_id] = production_downloaded
      if (oldSwapStatus[current_package.service_id]){
        delete oldSwapStatus[current_package.service_id]
      }
      this.setState({swapStatus: newSwapStatus})
      updateProjectDetails(data).then(this.props.reloadProject()).then(() => this.setState({swapStatus: oldSwapStatus}))
    } else {
      this.props.showToast("Star change being processed")
    }
  }

  copyEmbeddedCode(media_url) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(`<video className="galleryPhotoLarge galleryVideoLarge" controls>
            <source src=${media_url} type='video/mp4' />
       </video>`)
    } else {
      navigator.clipboard.writeText(
        `<video className="galleryPhotoLarge galleryVideoLarge" controls>
            <source src=${media_url} type='video/mp4' />
       </video>`
      )
    }
    this.props.showToast("Link copied")
    // this.setState({copied: {[media_url]: true}})
  }

  copyMLSCode(media_id, slug) {
    // Construct the tour link with 'tour' subdomain
    const hostname = window.location.host;
    const protocol = window.location.protocol;

    // Remove 'www' if it exists in the hostname
    const baseHost = hostname.startsWith('www.') ? hostname.slice(4) : hostname;
    const tourBaseUrl = `${protocol}//tour.${baseHost}`;
    const tourLink = `${tourBaseUrl}/${slug}/${media_id}`;

    // Copy the link to the clipboard
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(tourLink);
    } else {
      navigator.clipboard.writeText(tourLink);
    }

    // Show a toast notification
    this.props.showToast("Link copied");
  }

  copyLink(text){
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text)
    } else {
      navigator.clipboard.writeText(text
      )
    }
    // this.setState({ copiedLink: true })
    this.props.showToast("Link copied")
  }

  copySnippet(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text)
    } else {
      navigator.clipboard.writeText(text
      )
    }

    this.props.showToast("Link copied")
    // this.setState({ copiedSnippet: true })
  }

  onClose(){
    this.setState({downloadModal: false})
  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    document.execCommand('copy')

    document.body.removeChild(textArea)
  }

  onInputChange(field) {
    return (e) => {
      e.preventDefault()
      this.setState({ [field]: e.target.value })
    }
  }

  submitMatterport() {
    if (this.state.upload_link !== this.props.project.external_model_id){
      const { selectedServiceIndex } = this.state
      const services = this.services()
      const selectedService = services[selectedServiceIndex]
      const selectedServiceId = selectedService?.id
      const access_code = this.props.match.params?.accessCode

      let data = { id: this.props.project.id, access_code: access_code, project: { id: this.props.project.id, external_model_id: this.state.upload_link } }

      // let formData = new FormData()
      // formData.append("project[external_model_id]", this.state.upload_link)
      // formData.append("id", this.props.project.id)
      // formData.append("access_code", access_code)

      updateProjectDetails(data, this.props.project.id).then(() => {
        this.setState({ success_message: "", uploadDisabled: true })
        this.props.reloadProject(selectedServiceId)
      })
    }
  }


  getDownloadInfo() {
  if (Object.values(this.state.selectedMedia).length === 0) {
    this.props.showToast("No media selected")
  } else {
    if ((this.props.currentUser?.roles?.client || this.props.currentUser?.roles?.broker || this.props.currentUser?.roles?.sub_broker) && Object.values(this.state.selectedMedia).length > 10){
      this.props.showToast("Only 10 photos can be selected for download")
    } else {
      if (this.state.download_pending){
        this.props.showToast("Download currently pending")
      } else {
      let media_ids = []

      this.setState({download_pending: true})

      Object.values(this.state.selectedMedia).forEach((media) => {
        media_ids.push(media.id)
      })

      const access_code = this.props.match.params?.accessCode

      fetchBinaryData(media_ids, this.props.project.id, access_code).then(medias => {
        this.downloadMedia(medias)
      })
     }
    }
   }
  }

  // downloadAll(e, medias) {
  //   e.preventDefault()

  //   fetchBinaryData(medias.map(m => m.id)).then(medias => {
  //     this.downloadMedia(medias)
  //   })
  // }

  openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  downloadAll(){
    if (this.state.unprocessed && this.state.unprocessed_zip_worker_file){
      this.openInNewTab(this.state.unprocessed_zip_worker_file)
    } else if (!this.state.unprocessed && this.state.processed_zip_worker_file){
      this.openInNewTab(this.state.processed_zip_worker_file)
    }
  }

  downloadMedia(medias, photo = null, virtual_staging_purchase = null) {
    downloadMediaFolder(medias, this.props.project.address.street1, photo, virtual_staging_purchase).then(() => {
      this.setState({download_pending: false})
    })
  }

  downloadPhoto(e, photo, virtual_staging_purchase = null) {
    e.preventDefault()
    const access_code = this.props.match.params?.accessCode
    fetchBinaryData([photo.media_id], this.props.project.id, access_code).then(medias => {
      this.downloadMedia(medias, photo, virtual_staging_purchase)
    })
  }

  downloadVirtualMedia(medias, product) {
    const zip = new JSZip()

    const img = zip.folder(this.props.project.address.street1)

    Object.values(medias).forEach((media, i) => {
      let file_extention = media[1].split("/")[1]
      if (file_extention === "x-nikon-nef") {
        file_extention = "NEF"
      }
      img.file(`${product}_${i + 1}.${file_extention}`, media[0], {base64: true})
    })

    zip.generateAsync({type: "blob"}).then(function (content) {
      FileSaver.saveAs(content, "modern_angles_media.zip")
    })
  }

  selectService(e, i) {
    e.preventDefault()

    const services = this.services()

    if (i === this.state.selectedServiceIndex) return
    this.setState({selectedServiceIndex: i})
    this.reloadProjectMedias(services[i].id)
  }

  onDeleteMedia() {
    if (Object.values(this.state.selectedMedia).length === 0){
      this.props.showToast("No photos selected")
    } else {
      if (!window.confirm('Are you sure you want to delete these photos?')) return
      const media_ids = Object.values(this.state.selectedMedia).map(media => media.id)
      const {selectedServiceIndex} = this.state
      const packages = Object.values(this.props.project.packages || [])
      let services = this.props.currentUser?.roles?.contractor ?
        this.props.project.services.filter(s => {
          return packages.find(p => p.contractor_id === this.props.currentUser?.id && p.service_id === s.id)
        }) :
        this.props.project.services

      if (this.props.currentUser?.roles?.post_production || (this.props.project.upload_key && this.props.match.params.accessCode === this.props.project.upload_key)) {
        services = this.props.project.services.filter(s => {
          return packages.find(p => !(p.service_title === "Floor Plans" && p.cubicasa) && s.media_types !== "matterport" && p.service_id === s.id)
        })
      }

      const selectedService = services[selectedServiceIndex]
      const selectedServiceId = selectedService?.id
      const access_code = this.props.match.params?.accessCode

      deleteMedia(media_ids, access_code, this.props.project.id).then((result) => {
        this.reloadProjectMedias(selectedServiceId).then(() => {
          this.setState({selectedMedia: {}})
        })

        if (result.notice){
          this.props.showToast(result.notice)
        }
      })
    }
  }

  selectMedia(media, i) {
    if (this.state.photoSelection) {
      let medias = Object.assign({}, this.state.selectedMedia)

      if (medias[media.id]) {
        delete medias[media.id]
      } else {
        medias[media.id] = media
      }

      this.setState({selectedMedia: medias})
    } else {
      this.setState({galleryModal: true, currentPhoto: i})
    }
  }

  openVirtualStagingGallery(media){
    this.setState({virtualStagingGalleryModal: true, virtualStagingPhoto: media.url})
  }

  selectAllMedia(medias) {
    let selected_medias = this.state.selectedMedia

    medias.forEach(media => selected_medias[media.id] = media)

    this.setState({selectedMedia: selected_medias})
  }

  openUploadModal(e) {
    e.preventDefault()
    this.setState({modalOpen: true})
  }

  enableSelection(e) {
    e.preventDefault()
    this.setState({photoSelection: true})
  }

  disableSelection(e) {
    e.preventDefault()
    this.setState({photoSelection: false, selectedMedia: {}})
  }

  clearSelection(e) {
    e.preventDefault()
    this.setState({selectedMedia: {}})
  }

  submitMatterportFromUploadModal(link) {
    const access_code = this.props.match.params?.accessCode
    const selectedService = this.selectedService()

    let data = {
      id: this.props.project.id,
      access_code: access_code,
      project: { id: this.props.project.id, external_model_id: link } }

    updateProjectDetails(data, this.props.project.id).then(() => {
      this.setState({modalOpen: false})
      this.reloadProjectMedias(selectedService?.id)
    })
  }

  submitPhotosFromUploadModal(project, service, unprocessed, medias) {
    const access_code = this.props.match.params?.accessCode
    const formData = new FormData()

    formData.append("media_upload[project_id]", project.id)
    formData.append("media_upload[service_id]", service.id)

    if (this.props.currentUser?.roles?.admin && unprocessed) {
      let selected_package = Object.values(project.packages).find((current_package) => current_package.service_id === service.id)
      formData.append("media_upload[uploading_user_id]", selected_package.contractor_id)
    } else if (!this.props.currentUser && access_code === project.upload_key){
      formData.append("media_upload[uploading_user_id]", project.post_production_user_id)
    } else {
      formData.append("media_upload[uploading_user_id]", this.props.currentUser?.id)
    }

    for (let i = 0; i < medias.length; i++) {
      formData.append("media_upload[medias][]", medias[i])
    }

    formData.append("access_code", access_code)

    return uploadPhotos(formData).then((result) => {
      this.reloadProjectMedias(service.id, true)
      return result
    })
  }

  services() {
    const packages = Object.values(this.props.project.packages || [])

    let services = this.props.currentUser?.roles?.contractor ?
      this.props.project.services.filter(s => {
        return packages.find(p => p.contractor_id === this.props.currentUser?.id && p.service_id === s.id)
      }) :
      this.props.project.services

    if (this.props.currentUser?.roles?.post_production || (this.props.project.upload_key && this.props.match.params.accessCode === this.props.project.upload_key)) {
      services = this.props.project.services.filter(s => {
        return packages.find(p => !(p.service_title === "Floor Plans" && p.cubicasa) && s.media_types !== "matterport" && p.service_id === s.id)
      })
    }

    return services
  }
  selectedService() {
    return this.services()[this.state.selectedServiceIndex]
  }

  render() {
    const {selectedServiceIndex, unprocessed} = this.state
    const packages = Object.values(this.props.project?.packages || [])
    const project_services = this.props.project?.services || []
    let services = this.props.currentUser?.roles?.contractor ?
      project_services.filter(s => {
        return packages.find(p => p.contractor_id === this.props.currentUser?.id && p.service_id === s.id)
      }) :
      project_services

    if (this.props.currentUser?.roles?.post_production || (this.props.project?.upload_key && this.props.match.params.accessCode === this.props.project?.upload_key)) {
      services = project_services.filter(s => {
        return packages.find(p => !(p.service_title === "Floor Plans" && p.cubicasa) && s.media_types != "matterport" && p.service_id === s.id)
      })
    }

    const {currentUser, project} = this.props
    const selectedService = services[selectedServiceIndex]
    const selectedServiceId = selectedService?.id
    const selectedText = this.props.isLargeScreen ? "selected" : ""
    const selected_package = packages.find(p => p.service_id === selectedServiceId)

    let modal = ""
    let projectEditPhotosUlId = ""
    let projectEditUl = ""
    let selectionButtons = ""

    let medias = unprocessed ?
      this.state.unprocessedMedias :
      this.state.processedMedias

    let photos = 0;

    const canUpload = (currentUser?.roles?.admin || ((currentUser?.roles?.post_production || (project?.upload_key && this.props.match.params.accessCode === project?.upload_key)) && !unprocessed) ||
      (currentUser?.roles?.contractor && unprocessed)) && !(selectedService?.title === "Virtual Staging" && unprocessed)

    const canDelete = currentUser?.roles?.admin || ((currentUser?.roles?.post_production || (project?.upload_key && this.props.match.params.accessCode === project?.upload_key)) && !unprocessed) ||
      currentUser?.roles?.contractor

    const canSelect = medias.length > 0 && (selectedService?.media_types === "image" || selectedService?.media_types === "video") && (currentUser || (project?.upload_key && this.props.match.params.accessCode === project?.upload_key))
    const isAdmin = (currentUser?.roles?.admin || currentUser?.roles?.post_production || (project?.upload_key && this.props.match.params.accessCode === project?.upload_key))
    const isClient = (currentUser?.id === project?.client?.id)
    const isClientsBroker = (currentUser?.roles?.broker && currentUser?.firm?.id === project?.client?.firm?.id)

    if (this.state.photoSelection === false && canSelect) {
      selectionButtons = <div className="projectEditPhotosButtons">
        <div onClick={this.enableSelection} className="submitPhotosButton">
          Select
        </div>
      </div>
    } else if (canSelect) {
      selectionButtons = <div className="projectEditPhotosButtons">
        <div className="numberSelectedPhotos">{Object.values(this.state.selectedMedia).length} {selectedText}</div>

        <div className="submitPhotosButton deletePhotoButton" onClick={() => this.selectAllMedia(medias)}>
          {this.props.isLargeScreen ? "Select All" : "All"}
        </div>

        <div className="submitPhotosButton deletePhotoButton clearSelectionButton" onClick={this.clearSelection}>
          Clear
        </div>

        {canDelete && <div className="submitPhotosButton deletePhotoButton" onClick={this.onDeleteMedia}>
          Delete
        </div>}

        {selectedService?.media_types != "video" && <div className="submitPhotosButton" onClick={this.getDownloadInfo}>
          Download
        </div>}
        <div onClick={this.disableSelection} className="submitPhotosButton">
          Cancel
        </div>
      </div>
    }

    let selectedId = "projectEditPhotoSelected"

    if (selectedService?.media_types === "image") {
      if (selectedService?.title === "Virtual Staging" && ((currentUser?.roles?.admin || currentUser?.roles?.post_production || (project?.upload_key && this.props.match.params.accessCode === project?.upload_key)) && unprocessed)) {
        projectEditUl = Object.values(project?.virtual_staging_purchases || [])
          .sort((a, b) => {
            if (a.status === 'complete' && b.status !== 'complete') return 1;
            if (a.status !== 'complete' && b.status === 'complete') return -1;
            return 0;
          }).map((virtual_staging_purchase, i) => {
          return Object.values(virtual_staging_purchase?.photos || []).map(photo => {
            photos += 1
            return <div className="editProjectVirtualStagingItem" key={photo.id}>
              <div className="editProjectVirtualStagingItemImage" onClick={() => this.openVirtualStagingGallery(photo)}>
                <img src={photo.url}/>
              </div>

              <div className="editProjectVirtualStagingItemDescription">
                <table>
                  <tbody>
                  <tr>
                    <td>Image:</td>
                    <td className="w-100">
                      <div className="d-flex align-items-center">
                          <a href={photo.url} target="_blank" download={photo.file_name} className="text-truncate tuncated-text">
                          {photo.file_name}
                        </a>
                        <a href={photo.url} onClick={e => this.downloadPhoto(e, photo, virtual_staging_purchase)} className="ms-2 downloadIcon">
                          <DownloadIcon/>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Type:</td>
                    <td>{virtual_staging_purchase.virtual_suite_type?.name}</td>
                  </tr>
                  <tr>
                    <td>Category:</td>
                    <td>{virtual_staging_purchase.category?.name}</td>
                  </tr>
                  <tr>
                    <td>Style:</td>
                    <td>{virtual_staging_purchase.product?.name}</td>
                  </tr>
                  <tr>
                      {isClient || currentUser.roles.admin ? <td onClick={() => this.props.openNoteModal(virtual_staging_purchase, photo)} className="addressLink">Notes:</td> : <td>Notes:</td>}
                    <td>{photo.notes || virtual_staging_purchase.notes}</td>
                  </tr>
                  <tr>
                    <td>Ordered:</td>
                    <td>{new Date(virtual_staging_purchase.created_at).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                      <td className={virtual_staging_purchase.status === "complete" ? "virtualStagingCompleted" : "virtualStagingPending"}>{virtual_staging_purchase.status === "complete" ? "Completed" : "Requested"}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          })
        })
      } else {
        if (this.state.unprocessed){
          projectEditUl = <>
            {medias.slice(0, this.state.streamAmount).map((media, i) => {
              let text = "";
              if (media.file_name?.slice(media.file_name.length - 4, media.file_name.length) === ".CR2" || media.file_name?.slice(media.file_name.length - 4, media.file_name.length) === ".NEF" || media.file_name?.slice(media.file_name.length - 4, media.file_name.length) === ".DNG" || media.file_name?.slice(media.file_name.length - 4, media.file_name.length) === ".ARW") {
                text = media.file_name
                return (<div onClick={() => this.selectMedia(media, i)} className="editProjectPhoto" key={media.id}
                            id={this.state.selectedMedia[media.id] ? selectedId : ""}>
                  <div className="projectEditTextImage">{text}</div>
                </div>)
              } else {
                return (<div onClick={() => this.selectMedia(media, i)} className="editProjectPhoto" key={media.id}
                            id={this.state.selectedMedia[media.id] ? selectedId : ""}>
                  <img src={media.url}/>
                  <div className="filename">{media.file_name}</div>
                </div>)
              }
            })}
          </>
        } else {
          projectEditUl = <>
            {medias.map((media, i) => {
              let text = "";
              if (media.file_name?.slice(media.file_name.length - 4, media.file_name.length) === ".CR2" || media.file_name?.slice(media.file_name.length - 4, media.file_name.length) === ".NEF" || media.file_name?.slice(media.file_name.length - 4, media.file_name.length) === ".DNG" || media.file_name?.slice(media.file_name.length - 4, media.file_name.length) === ".ARW"){
                text = media.file_name
                return (<div onClick={() => this.selectMedia(media, i)} className="editProjectPhoto" key={media.id}
                  id={this.state.selectedMedia[media.id] ? selectedId : ""}>
                  <div className="projectEditTextImage">{text}</div>
                </div>)
              } else {
                return (<div onClick={() => this.selectMedia(media, i)} className="editProjectPhoto" key={media.id}
                  id={this.state.selectedMedia[media.id] ? selectedId : ""}>
                  <img src={media.url} />
                  <div className="filename">{media.file_name}</div>
                </div>)
              }
            })}
          </>
        }
      }
    } else if (selectedService?.media_types === "video") {
      if (this.props.currentUser?.roles?.contractor || ((currentUser?.roles?.admin || currentUser?.roles?.post_production || (project?.upload_key && this.props.match.params.accessCode === project?.upload_key)) && unprocessed)) {
        projectEditUl = <>
          {medias.slice(0, this.state.streamAmount).map((media, i) => {
            return (
              <div onClick={() => this.state.photoSelection ? this.selectMedia(media, i) : ""} className="editProjectPhoto editProjectVideo" key={media.id}
                   id={this.state.selectedMedia[media.id] ? selectedId : ""}>
                <video controls id={this.state.photoSelection ? "disabledVideo" : ""}>
                  <source src={`${media.url}#t=5`} type="video/mp4"/>
                </video>
                <div className="filename">{media.file_name}</div>
              </div>
            )
          })}
        </>
      } else {
        projectEditUl = <>
          {medias.slice(0, this.state.streamAmount).map((media, i) => {
            return (
              <div onClick={() => this.state.photoSelection ? this.selectMedia(media, i) : ""} className="editProjectPhoto editProjectVideo editProjectVideoProcessed" key={media.id}
                   id={this.state.selectedMedia[media.id] ? selectedId : ""}>
                <InView as="div" onChange={(inView) => this.changeVideoState(inView)}>
                  <video controls muted playsInline ref={this.videoRef} preload="metadata" id={this.state.photoSelection ? "disabledVideo" : ""}>
                    <source src={`${media.url}`} type="video/mp4" />
                  </video>
                </InView>
                <div className="filename">{media.file_name}</div>
                <div className="textUnderVideo">
                  {/* <div><a className="small" href={media.url}>Download</a></div> */}

                  <div className="embedededCodeDiv">
                    <div className="videoEmbeddedClipboard videoEmbeddedClipboardTop" onClick={() => this.copyMLSCode(media.id, project?.slug)}>Click here to copy MLS URL link</div>
                    <div className="videoEmbeddedClipboard" onClick={() => this.copyEmbeddedCode(media.url)}>Click here to copy embedded code</div>
                  </div>
                  {this.state.copied[media.url] && <div className="textCopiedText small">Code copied!</div>}
                </div>
              </div>
            )
          })}
        </>
      }
    } else if (selectedService?.media_types === "matterport" && this.props.project?.external_model_id) {

      let iframeCode = `<iframe width = '853' height = '480' frameborder = '0' allowfullscreen allow = 'xr-spatial-tracking' src="${this.props.project?.external_model_id}"/>`
      projectEditUl = <div className="matterportProjectPageContainer">
        <div className="matterportEditOption matterportEditOptionMain">
          <div className="inputGroup matterportInputGroup">
            <label className="matterportLabel">Interactive Tour Code Snippet</label>
            <textarea className="formTextArea" disabled="true" value={iframeCode} />
          </div>
          <div className="projectEditButton projectEditButtonPrimary matterportButton" onClick={() => this.copySnippet(iframeCode)}>Copy Snippet</div>
          {this.state.copiedSnippet && <div className="matterportCopiedText small">Code copied!</div>}
        </div>
        <div className="matterportEditOption matterportEditOptionMain">
          <div className="inputGroup matterportInputGroup">
            <label className="matterportLabel">Preview Link</label>
            <input className="formInput matterportDesignDiv" disabled="true" value={this.props.project?.external_model_id} />
          </div>
          <div className="projectEditButton projectEditButtonPrimary matterportButton" onClick={() => this.copyLink(this.props.project?.external_model_id)}>Copy Link</div>
          {this.state.copiedLink && <div className="matterportCopiedText small">Code copied!</div>}
        </div>
        <iframe className="video-project-edit" src={this.props.project?.external_model_id}/>
      </div>
    }

    if (selectedService?.title === "Virtual Staging" && !unprocessed && medias.length === 0) {
      projectEditPhotosUlId = "projectEditPhotosUlEmpty"
      projectEditUl = <div className="text-center">
        <div className="mt-2">Media for this package is still being processed</div>
        {canUpload && <button className="projectEditButton projectEditButtonPrimary m-auto mt-2"
                              onClick={this.openUploadModal}>
            Upload media
          </button>}
      </div>
    }

    if (this.state.modalOpen) {
      modal = <UploadPhotosModal submitPhotosFromUploadModal={this.submitPhotosFromUploadModal}
                                 submitMatterport={(link) => this.submitMatterportFromUploadModal(link)}
                                 current_service={selectedService}
                                 project={this.props.project}
                                 unprocessed={this.state.unprocessed}
                                 onClose={() => this.setState({modalOpen: false})}
                                 isLargeScreen={this.props.isLargeScreen}/>
    }

    if (this.state.galleryModal) {
      modal = <GalleryModal photos={medias} changeParentState={this.handler} current_service={selectedService}
                            currentPhoto={this.state.currentPhoto}/>
    } else if (this.state.downloadModal){
      modal = <DownloadModal project={this.props.project} current_service={selectedService} onClose={this.onClose}/>
    } else if (this.state.virtualStagingGalleryModal){
      modal = <GalleryModal photos={[this.state.virtualStagingPhoto]} changeParentState={this.handler} currentPhoto={0} onClose={() => this.setState({virtualStagingGalleryModal: false})}/>
    } else if (this.state.shareMediaModal){
      modal = <ShareMediaModal project={this.props.project} changeParentState={this.handler} />
    }

    const downArrow = (medias.length > this.state.streamAmount) && this.state.unprocessed && selectedService?.title != "Virtual Staging" ? (
      <div className="editPhotosDownArrow"
           onClick={() => this.setState({streamAmount: this.state.streamAmount + 12})}>
        <img src={arrowDownSrc}/>
      </div>) : <></>

    let matterportAdminOption = ""

    if (selectedService?.media_types === "matterport" && (currentUser?.roles?.admin || currentUser?.roles?.post_production || (project?.upload_key && this.props.match.params.accessCode === project?.upload_key))){
      matterportAdminOption =
      <div className="matterportEditOption">
      <div className="inputGroup matterportInputGroup matterportInputGroupSpecial">
        <label className="matterportLabel">Upload Link</label>
        <input className="formInput matterportDesignDiv" placeholder="Upload Link" disabled={this.state.uploadDisabled} onChange={this.onInputChange('upload_link')}
            value={this.state.upload_link} />
       </div>
          {this.state.uploadDisabled ? <div className="projectEditButton projectEditButtonPrimary matterportButton" onClick={() => this.setState({ uploadDisabled: false })}>Edit</div> : <div className="projectEditButton projectEditButtonPrimary matterportButton" onClick={this.submitMatterport}>Submit</div>}
      </div>
    }


    if (this.state.loading){
      projectEditUl = <div className="loaderDiv">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    }

    return (
      <div>
        {modal}
        <div className="editProjectPhotosServices">
          {services.map((service, i) => {
            const ServiceIcon = serviceIcon(service.slug)
            const active_package = Object.values(project?.packages || []).find(current_package => current_package.service_id === service.id)
            return <div
              className={`editProjectPhotosService ${selectedServiceIndex === i ? 'selected' : ''} ${selectedServiceIndex === i + 1 ? 'prev-selected' : ''}`}
              onClick={e => this.selectService(e, i)}
              key={`service-${i}`}>
              {this.props.isLargeScreen ? <div>{service.title}</div> : <div><ServiceIcon className="editProjectMediaServiceIcon" /></div>}
            </div>
          })}
          <div className="editProjectPhotosService">
            <div/>
          </div>
        </div>
        <div className="editProjectPhotosSectionInner">
          <div className="d-flex justify-content-between flex-wrap buttonProjectEditGroup">
            <div className="d-flex align-items-center buttonProjectEditGroup">
              {matterportAdminOption}
              {selectedService?.media_types != "matterport" &&
                <button className="projectEditButton projectEditMarginBottomButton projectEditButtonPrimary downloadAllButton" id={(medias.length === 0 && photos === 0) || (this.state.unprocessed && !this.state.unprocessed_zip_worker_file) || !this.state.unprocessed && !this.state.processed_zip_worker_file ? "disabledButton" : ""} onClick={e => (medias.length === 0 && photos === 0) || (this.state.unprocessed && !this.state.unprocessed_zip_worker_file) || !this.state.unprocessed && !this.state.processed_zip_worker_file ? "" : this.downloadAll(e, medias)}>
                <img src={mediaDownloadIconSrc} className="me-2"/>
                  Download {selectedService?.title} {isAdmin && selectedService?.media_types === "image" ? photos > 0 ? `(${photos})`: `(${medias.length})` : ""}
              </button>}
              {canUpload && selectedService && selectedService?.media_types != "matterport" && !(selectedService?.title === "Floor Plans" && this.state.manualUpload === false && this.state.unprocessed) ? <button className="projectEditButton projectEditMarginBottomButton projectEditButtonPrimary projectEditButtonUpload downloadAllButton" onClick = { this.openUploadModal }>
                Upload
              </button> : ""}
              {canUpload && selectedService?.title === "Floor Plans" && this.state.manualUpload === false && this.state.unprocessed ? <button className="projectEditButton projectEditMarginBottomButton projectEditButtonPrimary projectEditButtonUpload" onClick={() => this.setState({manualUpload: true})}>
                Enable Manual Upload
              </button> : ""}

            {/* {(isClient || isAdmin || isClientsBroker) && currentUser && !this.state.unprocessed && !this.props.currentUser?.roles?.post_production && <button className="projectEditButton projectEditMarginBottomButton projectEditButtonPrimary downloadAllButton projectEditButtonUpload" onClick={() => this.copySnippet(`${window.location.origin}/projects_index/${this.props.project.slug}/share/${this.props.project.download_key}`)}>
              Share Media
            </button>} */}

              {(isClient || isAdmin || isClientsBroker) && currentUser && selectedService?.media_types != "matterport" && !this.state.unprocessed && !this.props.currentUser?.roles?.post_production && <button className="projectEditButton projectEditMarginBottomButton projectEditButtonPrimary downloadAllButton projectEditButtonUpload" onClick={() => this.setState({shareMediaModal: true})}>
              Share Media
            </button>}

            {isAdmin && currentUser && selectedService?.media_types != "matterport" && <button className="projectEditButton projectEditMarginBottomButton projectEditButtonPrimary downloadAllButton projectEditButtonUpload" onClick={() => this.copySnippet(`${window.location.origin}/projects_index/${this.props.project?.slug}/access/${this.props.project?.upload_key}`)}>
              Share Access
            </button>}
            </div>

             {canUpload && currentUser && !currentUser?.roles?.post_production && selectedService?.title === "Floor Plans" && this.state.unprocessed &&
              <div className="projectEditButton projectEditMarginBottomButton projectEditButtonUpload projectEditButtonPrimary downloadAllButton" id={selected_package.cubicasa ? "greenButton" : "redButton"}>
                {selected_package.cubicasa ? "CubiCasa Active" : "CubiCasa Inactive"}
              </div>}

            {isAdmin && selectedService?.media_types != "matterport" && <div className="d-flex justify-content-end">
              <button onClick={() => this.setState({ unprocessed: true, selectedMedia: {}})}
                className={`projectEditButton projectEditMarginBottomButton projectEditUnprocessedButton ${unprocessed ? 'projectEditButtonPrimary' : 'projectEditButtonLight'}`}>
                Unprocessed
              </button>

              <button onClick={() => this.setState({unprocessed: false, selectedMedia: {}})}
                className={`projectEditButton projectEditMarginBottomButton projectEditProcessedButton ${unprocessed ? 'projectEditButtonLight' : 'projectEditButtonPrimary'}`}>
                Processed
              </button>
            </div>}
          </div>

          <div className="divider"/>

          {selectionButtons}

          <div className="editProjectPhotos" id={projectEditPhotosUlId}>
            {projectEditUl}
          </div>
          <div>
            {downArrow}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EditProjectMedia);