export const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');

  if (hostname === 'localhost') {
    // Handle localhost specifically
    return null; // No subdomain for localhost
  }

  if (parts.length > 2 || (parts.length === 2 && parts[1] === 'localhost')) {
    return parts[0]; // Extract subdomain, e.g., 'tour' in 'tour.localhost'
  }

  return null; // No subdomain detected
};