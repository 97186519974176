import React from "react";
import { withRouter } from "react-router-dom";
import ServiceItem from "../create_project/serviceItem";
import ServiceSubservices from "../create_project/service_subservices";
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject.svg";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import BundleGroup from "./bundle_group"
import debounce from 'debounce'

class ServicesSelectionComponent2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      updated_service: false,
      minimize: this.props.minimize
    }

    this.handler = this.handler.bind(this)
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
  }

  handler(newState) {
    if (newState.errors) {
      this.setState(newState)
    } else {
      this.setState({ errors: [] })
      this.props.changeParentState(newState)
    }
  }

  scrollTo(selector) {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth', // Optional for smooth scrolling
        block: 'center',    // Scrolls the element to the center of the viewport
        inline: 'nearest'   // Keeps the element in the nearest horizontal position
      });
    }
  }

  render() {
    const selectedService = this.props.services[this.props.selected_service]
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""
    const allErrors = [...this.state.errors, ...this.props.summaryErrors]

    const selectedSubServices = Object.values(this.props.selectedSubservices)
    const selectedSubServicesByService = selectedSubServices.reduce((res, subService) => {
      if (res[subService.service_id]) {
        res[subService.service_id].push(subService)
      } else {
        res[subService.service_id] = [subService]
      }
      return res
    }, {})

    if (!this.props.bundleSelected){
      let servicesLis = []
      if (this.props.services) {
        if (this.props.specific_service !== -1) {
          servicesLis = Object.values(this.props.services || []).filter(service => service.id === this.props.specific_service).sort(function (a, b) { return a.sort_order - b.sort_order }).map((service) => <ServiceItem location_pricing={this.props.location_pricing} key={service.id} scrollTo={this.debouceScrollTo} selectedSubservices={selectedSubServicesByService[service.id]} zipcode={this.props.zipcode} service={service} changeParentState={this.handler} selected={this.props.selected_service === service.id} cancelled={this.props.cancelledPackages[service.id] && this.props.package_attributes[service.id] && !this.props.package_attributes[service.id]["appointment_time"]} previouslySelected={this.props.package_attributes[service.id] && Object.values(this.props.package_attributes[service.id]["package_sub_services_attributes"] || {}).length > 0} />)
        } else {
          if (this.props.previous_services){
            let previous_services = {}
            this.props.previous_services.forEach(service => previous_services[service.id] = true)
            servicesLis = Object.values(this.props.services || []).filter(service => service.subServices.filter(sub_service => this.props.addonServices ? sub_service.addon : !sub_service.addon).length > 0 && !previous_services[service.id]).sort(function (a, b) { return a.sort_order - b.sort_order }).map((service) => <ServiceItem location_pricing={this.props.location_pricing} key={service.id} scrollTo={this.debouceScrollTo} selectedSubservices={selectedSubServicesByService[service.id]} zipcode={this.props.zipcode} service={service} changeParentState={this.handler} selected={this.props.selected_service === service.id} cancelled={this.props.cancelledPackages[service.id] && this.props.package_attributes[service.id] && !this.props.package_attributes[service.id]["appointment_time"]} previouslySelected={this.props.package_attributes[service.id] && Object.values(this.props.package_attributes[service.id]["package_sub_services_attributes"] || {}).length > 0} />)
          } else {
            servicesLis = Object.values(this.props.services || []).filter(service => service.subServices.filter(sub_service => this.props.addonServices ? sub_service.addon : !sub_service.addon).length > 0).sort(function (a, b) { return a.sort_order - b.sort_order }).map((service) => <ServiceItem location_pricing={this.props.location_pricing} key={service.id} scrollTo={this.debouceScrollTo} selectedSubservices={selectedSubServicesByService[service.id]} zipcode={this.props.zipcode} service={service} changeParentState={this.handler} selected={this.props.selected_service === service.id} cancelled={this.props.cancelledPackages[service.id] && this.props.package_attributes[service.id] && !this.props.package_attributes[service.id]["appointment_time"]} previouslySelected={this.props.package_attributes[service.id] && Object.values(this.props.package_attributes[service.id]["package_sub_services_attributes"] || {}).length > 0} />)
          }
        }
      }

      if (this.props.appointments[this.props.selected_service] && this.state.errors.length > 0) {
        this.setState({ errors: [] })
      }

      const isSubServiceSelection = !!this.props.currentlySelectedService

      if (this.state.minimize) {
        minimizeId = "hidePropertyInputs"
        minimized = "projectHeadingMinmizedView"

        if (this.props.page && this.props.page > 2) {
          checkmark = "createProjectCheckmarkShow"
        }
      }

      if (servicesLis.length === 0 && this.props.loading === false) {
        servicesLis.push(<div className="unfortunateDiv"><div className="unfortunate">Unfortunately there are no more services to add for this project.</div></div>)
      } else if (this.props.loading === true) {
        servicesLis = <div className="createProjectLogoSpinner">
          <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
        </div>
      }

      return (
        <div className="projectLocationComponent">
          <div className="projectLocationHeadingGrouping" id={minimized}>
            <div className="projectLocationHeadingMinimizedGrouping">
              <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
              <div className="settingsComponentHeading">
                {isSubServiceSelection ? `Choose Your ${selectedService.title} Package` : 'Choose Your Services'}
              </div>
            </div>
          </div>
          <div className="subServicesSubheading" id={minimizeId}>
            {/* {this.props.homepage ? selectedSubServices.length == 1 ? "Add another service to save 5%" : selectedSubServices.length == 2 ? "Add another service to save 10%" : selectedSubServices.length == 3 ? "Add another service to save 15%" : selectedSubServices.length > 3 ? "You have maximized your discount!" : "Add two or more services to increase your discount" : "Add services to fit your listing and budget."} */}
            Add services to fit your listing and budget.
          </div>

          <div className="subServi`cesHeadingBottomDivider" id={minimizeId} />

          {allErrors.length > 0 && <div className="errorDiv mt-3 serviceSelectionErrors" id={minimizeId}>{allErrors}</div>}

          {!isSubServiceSelection ? <div className="servicesList" id={minimizeId}>
            {servicesLis}
          </div> : <div id={minimizeId}>
            <ServiceSubservices selectedSubservices={this.props.selectedSubservices} appointments={this.props.appointments}
                                package_services={this.props.package_attributes}
                                active_service={this.props.currentlySelectedService}
                                addonServices={this.props.addonServices}
                                location_pricing={this.props.location_pricing}
                                previouslyChosen={this.props.previouslyChosen}
                                subservices={this.props.currentlySelectedService?.subServices}
                                scrollTo={this.debouceScrollTo}
                                changeParentState={this.handler} />
          </div>}
        </div>
      )
    } else {
      let bundleGroups = []

      if (!this.state.minimize){
        bundleGroups = this.props.bundle_groups.map(bundle_group => <BundleGroup changeParentState={this.handler} location_pricing={this.props.location_pricing} bundle_group={bundle_group} selected_bundle={this.props.selected_bundle} selectedSubservices={this.props.selectedSubservices} appointments={this.props.appointments} package_services={this.props.package_attributes} update_bundle={this.props.update_bundle} />)
      }

      if (this.state.minimize) {
        minimizeId = "hidePropertyInputs"
        minimized = "projectHeadingMinmizedView"

        if (this.props.page && this.props.page > 2) {
          checkmark = "createProjectCheckmarkShow"
        }
      }

      if (this.props.bundleLoading === true) {
        bundleGroups = <div className="createProjectLogoSpinner">
          <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
        </div>
      }

      return (
        <div className="projectLocationComponent">
          <div className="projectLocationHeadingGrouping" id={minimized}>
            <div className="projectLocationHeadingMinimizedGrouping">
              <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
              <div className="settingsComponentHeading">Photography Packages</div>
            </div>
          </div>

          {/* <div className="subServicesHeadingBottomDivider" id={minimizeId} /> */}

          <div className="subServicesSubheading" id={minimizeId}>Choose the perfect set of services for showcasing your listing.</div>
          {allErrors.length > 0 && <div className="errorDiv mt-3 serviceSelectionErrors" id={minimizeId}>{allErrors}</div>}
          <div className="bundlesList" id={minimizeId}>
            {bundleGroups}
          </div>
        </div>
      )
    }
  }
}

export default withRouter(ServicesSelectionComponent2);