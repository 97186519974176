import React from 'react';
import {PrivacyNoticeWrapper} from "./styles";

const PrivacyNoticeSection = () => {
  return <PrivacyNoticeWrapper>
    <p>
      Modern Angles LTD (<b>“Modern Angles”</b>, <b>“we”</b>, <b>“our”</b> and <b>“us”</b>) provides this Privacy
      Policy to let you (“you”) know our policies and procedures regarding the collection, use and
      disclosure of information through our website www.modernangles.com (the <b>“Website”</b>), and any
      other websites, features, applications, widgets or online services that are owned or controlled by
      Modern Angles and that post a link to this Privacy Policy (together with the Website, the
      “Service”), as well as any information Modern Angles collects offline in connection with the
      Service.</p>

    <p>
      This Privacy Policy is incorporated by reference into our Terms of Service.
    </p>

    <p>
      Please read this Privacy Policy carefully to understand our policies and practices regarding
      your information and how we will treat it. If you do not agree with our policies and practices, do
      not access or use the Service. By accessing or using the Service, you agree to this Privacy Policy.
    </p>

    <h2>
      1. INFORMATION COLLECTION
    </h2>

    <h3>
      Information You Provide to Us
    </h3>

    <p>
      When you use the Service, you may provide us with information about you. This may
      include your name and contact information, financial information to make or receive payment for
      services obtained through the Modern Angles platform, or information to help us fill out tax
      forms.
    </p>

    <ul>
      <li>
        <b>Personal Information:</b> In the course of using the Service, we may require or otherwise
        collect information that identifies you as a specific individual and can be used to contact
        or identify you (“Personal Information”). Examples of Personal Information include
        your name, email address, company address, billing address, and phone number.
      </li>
      <li>
        <b>Payment Information:</b> If you use the Service to make or receive payments, we will also
        collect certain payment information, such as credit card, banking or other financial
        account information, and billing address.
      </li>
      <li>
        <b>Identity Verification:</b> We may collect Personal Information, such as your date of birth or
        taxpayer identification number, to validate your identity or as may be required by law,
        such as to complete tax filings. We may request documents to verify this information,
        such as a copy of your government-issued identification or photo or a billing statement.
      </li>
      <li>
        <b>Biometric Identifiers or Service Interaction Information:</b> We may collect Personal
        Information such as a photograph of your face, a selfie, or data about your interactions
        with the Service to verify your identity and to detect fraud, identity theft, or other misuse
        of your account through the use of facial recognition and other technologies. We may
        request documents to verify this information, such as a copy of your government-issued
        identification. From time to time, Modern Angles may require you to take and submit an
        additional or updated image of your face for comparison to your government-issued
        identification. The biometric identifiers or information collected are used only for identity
        verification and platform security and use integrity purposes.
      </li>
      <li>
        <b>General Audience Service:</b> The Service is general audience and intended for users 18
        and older. We do not knowingly collect Personal Information from anyone younger than
        age 18. If you are under 18, you are not permitted to provide any information to or on the
        Service (including any information about yourself, such as your name, address, telephone
        number, email address or any screen name or username you may use). If we become
        aware that a child younger than 18 has provided us with Personal Information, we will
        delete such information from our files.
      </li>
      <li>
        <b>Combination of Personal and Non-Identifying Information:</b> Certain Non-Identifying
        Information would be considered a part of your Personal Information if it were combined
        with other identifiers in a way that enables you to be identified (for example, combining
        information with your name). But the same pieces of information are considered
        Non-Identifying Information when they are taken alone or combined only with other
        non-identifying information (for example, your viewing preferences). We may combine
        your Personal Information with Non-Identifying Information, but Modern Angles will
        treat the combined information as Personal Information.
      </li>
    </ul>

    <p>
      You do not have a statutory obligation to provide us with any information, but you may have a
      contractual obligation to do so, and if we do not receive certain information from you, then we
      will not be able to provide our Service to you. If you have any questions regarding whether
      provision of information is mandatory and the consequences for withholding such information,
      please contact us using the contact information below.
    </p>

    <h3>
      Information Collected Automatically
    </h3>

    <p>
      Like other companies, we receive technical information when you use our Services. We use
      these technologies to analyze how people use the Service, to improve how our Website functions,
      to save your log-in information for future sessions, and to serve you with advertisements that
      may interest you.
    </p>

    <p>
      We and our third party service providers, including analytics and third party content providers,
      may automatically collect certain information from you whenever you access or interact with the
      Service. This information may include, among other information, the browser and operating
      system you are using, the URL or advertisement that referred you to the Service, the search terms
      you entered into a search engine that led you to the Service, areas within the Service that you
      visited, which links you clicked on, which pages or content you viewed and for how long, other
      similar information and statistics about your interactions, such as content response times,
      download errors and length of visits to certain pages and other information commonly shared
      when browsers communicate with websites. We may combine this automatically collected log
      information with other information we collect about you. We do this to improve services we
      offer you, and to improve marketing, analytics, and site functionality.
    </p>

    <p>
      The information we collect also includes the Internet Protocol (<b>“IP”</b>) address or other unique
      device identifier (<b>“Device Identifier”</b>) for any device (computer, mobile phone, tablet, etc.) used
      to access the Service. A Device Identifier is a number that is automatically assigned or connected
      to the device you use to access the Service, and our servers identify your device by its Device
      Identifier. Some mobile service providers may also provide us or our third party service
      providers with information regarding the physical location of the device used to access the
      Service.
    </p>

    <p>
      Modern Angles uses cookies or similar technologies to analyze trends, administer the website,
      track users’ movement around the Website and the Modern Angles desktop app (the <b>“App”</b>) to
      gather demographic information about our user base as a whole. The technology used to collect
      information automatically from users may include the following:
    </p>

    <ul>
      <li>
        <b>Cookies:</b> Like many websites, we and our marketing partners, affiliates, analytics, and
        service providers use “cookies” to collect information. A cookie is a small data file that
        we transfer to your computer’s hard disk for record-keeping purposes. We use both
        persistent cookies that remain on your computer or similar device (such as to save your
        registration ID and login password for future logins to the Service and to track your
        compliance with the Modern Angles Terms of Service) and session ID cookies, which
        expire at the end of your browser session (for example, to enable certain features of the
        Service, to better understand how Modern Angles users interact with the Service and to
        monitor aggregate usage by Modern Angles users and web traffic routing on the Service).
        You can control the use of cookies at the individual browser level, but if you choose to
        disable cookies, it may limit your use of certain features or functionality of the Service.
      </li>
      <li>
        <b>Web Beacons:</b> We and our marketing partners, affiliates, analytics, and service providers
        may also employ software technology known as “web beacons” and/or “tracking tags” to
        help us keep track of what content on our Service is effective and to serve relevant
        advertising to you. Web beacons are small graphics with a unique identifier that may be
        invisible to you, and which are used to track the online activity of Internet users. Web
        beacons are embedded in the web pages you review or email messages you receive. Web
        beacons or similar technologies may be used for a number of purposes, including,
        without limitation, to count visitors to our Service, to monitor how our users navigate the
        Service, to count how many messages that were sent were actually opened, or to count
        how many particular articles or links were actually viewed.
      </li>
      <li>
        <b>Embedded Scripts:</b> We and our marketing partners, affiliates, analytics, and service
        providers may also employ software technology known as an Embedded Script. An
        Embedded Script is programming code that is designed to collect information about your
        interactions with the Service, such as the links you click on. The code is temporarily
        downloaded onto your computer or other device and is deactivated or deleted when you
        disconnect from the Service.
      </li>
    </ul>

    <p>
      In addition, we and our marketing partners, affiliates, analytics, and service providers may use a
      variety of other technologies (such as tags) that collect similar information for security and fraud
      detection purposes and we may use third parties to perform these services on our behalf.
    </p>

    <h3>
      How we respond to do not track signals
    </h3>

    <p>
      <b>Modern Angles does not respond to Do-Not-Track signals.</b>
      <br/>
      Please note that your browser setting may allow you to automatically transmit a “Do Not
      Track” signal to websites and online services you visit. There is no consensus among industry
      participants as to what “Do Not Track” means in this context. Like many websites and online
      services, Modern Angles does not alter its practices when it receives a “Do Not Track” signal
      from a visitor’s browser. To find out more about “Do Not Track,” please
      visit https://www.allaboutdnt.com.
    </p>

    <h3>
      User Profiles
    </h3>

    <p>
      You may have the opportunity to create a profile, which consists of information about
      you, and may include Personal Information, photographs, examples of your work, information on
      work previously performed via the Service and outside the Service, skills, feedback/rating
      information and other information, including your username (<b>“Profile”</b>). You may edit certain
      information in your Profile via your account settings page. Clients and Agencies of associated
      individual users or companies may also have the opportunity to create an organization Profile. If,
      in any case, you believe that an unauthorized profile has been created about you, you can request
      for it to be removed by contacting us at info@modernangles.com.
    </p>

    <h3>
      Identity Verification
    </h3>

    <p>
      We place a premium on trust in our Service, so we may take steps to verify your identity.
      Before you can complete registration on the Service, or at any time thereafter, we may request or
      re-request identity verification. Without limiting the manner in which we request identity
      verification, we may require you to participate in a video call after submitting your government
      issued ID to enable us to confirm that you are indeed the individual in the ID. We may record
      such video calls and take screenshots of you during the call. Modern Angles may use the
      information obtained from Identity Verification for purposes of verifying your identity, enforcing
      our Terms of Service and other agreements, and preventing fraud.
    </p>

    <h2>
      2. USE OF INFORMATION
    </h2>

    <p>
      We use information collected through the Service to provide and improve the Service,
      process your requests, prevent fraud, provide you with information and advertising that may
      interest you, comply with the law, and as otherwise permitted with your consent.
    </p>

    <h3>
      We Use Information We Collect:
    </h3>

    <ul>
      <li>
        To provide and improve the Service, complete your transactions, address your inquiries,
        process your registration, verify the information you provide is valid, give you notices
        about your account, and for compliance and internal business purposes.
      </li>
      <li>
        To tailor content we display to you and offers we may present to you, both on the Service
        and elsewhere online.
      </li>
      <li>
        To administer and develop our business relationship with you and, if applicable, the
        corporation or other legal entity you represent.
      </li>
      <li>
        To enforce and comply with the law, including to conduct an investigation, to protect the
        property and rights of Modern Angles or a third party, to protect the safety of the public
        or any person, or to prevent or stop activity we may consider to be, or to pose a risk of
        being, illegal, fraudulent, unethical or legally actionable activity.
      </li>
      <li>
        For the purposes disclosed at the time you provide your information, with your consent,
        and as further described in this Privacy Policy.
      </li>
    </ul>

    <h3>
      We use your Personal Information for the purposes described above:
    </h3>

    <ul>
      <li>
        To Honor Our Contractual Commitments to You. Much of our processing of Personal
        Information is to meet our contractual obligations, or to take steps at users’ request in
        anticipation of entering into a contract with them.
      </li>
      <li>
        For Our Legitimate Interests. In many cases, we handle Personal Information on the
        grounds that it furthers our legitimate interests in commercial activities, such as the
        following, in ways that are not overridden by the interests or fundamental rights and
        freedoms of the affected individuals:
        <ul>
          <li>Providing our Website and Service.</li>
          <li>
            Detecting security incidents, protecting against malicious, deceptive, fraudulent or
            illegal activity, and prosecuting those responsible for that activity.
          </li>
          <li>Measuring interest and engagement in our Services.</li>
          <li>Improving, upgrading or enhancing our Services.</li>
          <li>Developing new products and services.</li>
          <li>Ensuring internal quality control and safety.</li>
          <li>Authenticating and verifying individual identities.</li>
          <li>Debugging to identify and repair errors with our Services.</li>
          <li>Auditing relating to interactions, transactions and other compliance activities.</li>
          <li>Enforcing our agreements and policies.</li>
          <li>Analyzing and improving our business.</li>
          <li>
            Communications, including marketing and responding to your inquiries about our
            services.
          </li>
          <li>
            Addressing information security needs and protecting our users, Modern Angles,
            and others.
          </li>
          <li>Managing legal issues.</li>
        </ul>
        <li>
          To Comply with Legal Obligations. We need to use and disclose Personal Information in
          certain ways to comply with our legal obligations.
        </li>
      </li>
    </ul>

    <h2>
      3. DATA RETENTION
    </h2>

    <p>
      We store the personal information we collect as described in this Privacy Policy for as long
      as you use our Service or as necessary to fulfill the purpose(s) for which it was collected, provide
      our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate
      business purposes, enforce our agreements, and comply with applicable laws. Following
      termination or deactivation of an account, we may retain information and content for backup,
      archival, audit or other business purposes, or otherwise in accordance with applicable laws. We
      may maintain anonymized, de-identified or aggregated data, including usage data, for analytics
      purposes. If you have any questions about data retention or deletion, please email
      info@modernangles.com.
    </p>

    <h2>
      4. INFORMATION SHARING AND DISCLOSURE
    </h2>

    <p>
      We may share information about you to provide the Services, for legal and investigative
      purposes, in connection with marketing and promotions, or if we are part of a merger or
      acquisition. We may also share non-identifying information with third parties. You have choices
      as to whether we share your personal information with third parties for their own marketing
      purposes.
    </p>

    <p>
      We may share aggregated Non-Identifying Information and we may otherwise disclose
      Non-Identifying Information to third parties. We may disclose anonymized, de-identified or
      aggregated information about our users, and information that does not identify any individual,
      without restriction. We may also share aggregated, non-personally identifiable information
      publicly, including with users, partners or the press in order to, for example, demonstrate how the
      Service is used, evaluate industry trends, or to create our marketing materials. Any aggregated
      information shared this way will not contain any personal information.
    </p>

    <p>
      We do not share your Personal Information with third parties for those third parties’
      marketing purposes unless we first provide you with the opportunity to opt-in to or opt-out of
      such sharing. We may also share the information we have collected about you, including
      Personal Information, as disclosed at the time you provide your information, with your consent,
      as otherwise described in this Privacy Policy, or in the following circumstances:
    </p>

    <ul>
      <li>
        <b>Information about Consultants Shared with Clients, Agencies, and Modern Angles
          Payment Vendors:</b> We share information regarding Consultants who have entered into a
        project with an individual Client or Agency or who have connected to Modern Angles’
        payment vendor for the payment of completed projects. Note that if a Consultant is
        suspended from our Service, we may share that information with Clients for whom that
        Consultant has worked. We may also share information with Clients to whom Consultants
        are associated for a particular work project. We may share relevant information with
        applicable Clients seeking project work in your designated zip code area, including, but
        not limited to, the information contained in your Consultant Profile.
      </li>
      <li>
        <b>Information about Client Agencies Shared with Consultants:</b> If you have entered into
        a service contract or agreed to use Modern Angles’ platform to retain a Consultant for a
        project, we may provide him/her with your name, company address, billing address, or
        tax ID or VAT number in order to complete the transaction or to facilitate the resolution
        of a claim or dispute. The user receiving your information is not allowed to use it for
        purposes unrelated to the transaction, such as to contact you for marketing purposes,
        unless you have expressly consented to it.
      </li>
      <li>
        <b>Service Providers:</b> We may employ third party companies and individuals to facilitate
        our Service, to provide the Service on our behalf, to perform Service-related services
        (e.g., without limitation, maintenance services, database management, web analytics and
        online advertising, payment processing, fraud detection and improvement of Modern
        Angles’ features) or to assist us in analyzing how our Service is used. These third parties
        may have access to your Personal Information in order to perform these tasks on our
        behalf.
      </li>
      <li>
        <b>What Happens If You Agree to Receive Information from Third Parties or Request
          that We Share Your Information:</b> You may be presented with an opportunity to receive
        information and/or marketing offers from one or more third parties. If you agree at that
        time to have your Personal Information shared, your Personal Information will be
        disclosed to that third party (or parties) and will be subject to the privacy policy and
        practices of that third party. We are not responsible for the privacy policies and practices
        of third parties, and, if you later decide that you no longer want to receive
        communications from a third party, you will need to contact that third party directly. You
        also may request, sometimes through your use of an SNS or similar interactive feature or
        third party application, that we share information about you with a third party and we will
        typically do so under those circumstances.
      </li>
      <li>
        <b>Legal and Investigative Purposes:</b> Modern Angles will share information with
        government agencies as required by law in response to lawful requests by public
        authorities, including to meet national security or law enforcement requirements and,
        including without limitation, in connection with reporting earnings. We cooperate with
        government and law enforcement officials and private parties to enforce and comply with
        the law. We will disclose information about you to government or law enforcement
        officials or private parties as we, in our sole discretion, believe necessary or appropriate
        to respond to claims and legal process (including but not limited to subpoenas), or, at the
        request of governmental authorities or other third parties conducting an investigation
        where we determine in our sole discretion the disclosure is necessary to (a) protect the
        property and rights of Modern Angles or a third party, (b) protect the safety of the public
        or any person, or (c) prevent or stop activity we may consider to be, or pose a risk of
        being, illegal, fraudulent, unethical or legally actionable activity.
      </li>
      <li>
        <b>Internal and Business Transfers:</b> Modern Angles may share information, including Personal Information, with any current or future subsidiaries or affiliates, primarily for business and operational purposes, such as IT management, to provide services to you or to support and supplement the Services we provide. However, Google user data is not shared with subsidiaries, affiliates, or third parties for purposes unrelated to providing or improving our application's functionality.
        In the event of a merger, acquisition, reorganization, or sale of assets (including as part of the due diligence process with any potential acquiring entity) or in the event of bankruptcy, we may transfer some or all of our assets, including your Personal Information. Google user data will only be transferred if necessary to maintain the functionality of the application and in strict compliance with Google's data use policies.
      </li>
    </ul>
    <p>
      Modern Angles disclaims all liability for the disclosure of information (including health
      information) that you choose to submit voluntarily. You assume all such risks with regards to
      your use of the Service.
    </p>

    <h2>
      5. THIRD PARTY ANALYTICS PROVIDERS, AD SERVERS AND SIMILAR THIRD PARTIES
    </h2>

    <p>
      We may work with advertising agencies and vendors who use technology to help us
      understand how people use our Website. These vendors may use technologies to serve you
      advertisements that may interest you. You can choose to opt out of receiving interest-based
      advertising.
    </p>

    <p>
      Modern Angles works with (or may in the future work with) network advertisers, ad
      agencies, analytics service providers and other vendors to provide us with information regarding
      traffic on the Service, including pages viewed and the actions taken when visiting the Service; to
      serve our advertisements on other websites, within mobile apps and elsewhere online; and to
      provide us with information regarding the use of the Service and the effectiveness of our
      advertisements. Our service providers may collect certain information about your visits to and
      activity on the Service as well as other websites or services, they may set and access their own
      tracking technologies on your device (including cookies and web beacons), and may use that
      information to show you targeted advertisements. Some of these parties may collect Personal
      Information when you visit the Service or other online websites and services. We may also share
      certain Non-Identifying Information with these parties in connection with the services they
      provide to us.
    </p>

    <h2>
      6. YOUR CHOICES AND RIGHTS
    </h2>

    <p>
      According to applicable law, you may have certain choices and rights associated with your
      personal information.
    </p>

    <h3>
      Communication Preferences
    </h3>

    <ul>
      <li>
        Registered users may update their choices regarding the types of communications you
        receive from us through your online account.
      </li>
      <li>
        You may opt-out of receiving marketing emails from us by following the opt-out
        instructions provided in those emails. Please note that we reserve the right to send you
        certain communications relating to your account or use of the Service (for example,
        administrative and service announcements) via email and other means and these
        transactional account messages may be unaffected if you opt-out from receiving
        marketing communications.
      </li>
      <li>
        You may opt-out of receiving text messages by replying “STOP” to any text message
        received.
      </li>
      <li>
        Registered users who access the Service by using a Modern Angles mobile application
        may, with permission, receive push notifications. Similarly, registered users who access
        the Service by using certain desktop browsers may, with permission, receive push
        notifications. Notification preferences can be modified in the settings menu for the
        mobile application or the applicable browser.
      </li>
    </ul>

    <h3>
      Data Subject Rights.
    </h3>

    <p>
      In accordance with applicable law, you may have the right to:
    </p>

    <ul>
      <li>
        <b>Access Personal Information</b> about you, including: (i) confirming whether we are
        processing your personal information; (ii) obtaining access to or a copy of your personal
        information.
      </li>
      <li>
        <b>Request Correction</b> of your personal information where it is inaccurate, incomplete or
        outdated. In some cases, you can update your personal information within your Modern
        Angles account by logging in and visiting settings/user settings.
      </li>
      <li>
        <b>Request Deletion, Anonymization or Blocking</b> of your personal information when
        processing is based on your consent or when processing is unnecessary, excessive or
        noncompliant. Note that if your information is deleted, then your account may become
        deactivated. If your account is deactivated or you ask to close your account, you will no
        longer be able to use the Service. If you would like to close your account in our system,
        you can do so by contacting a Modern Angles administrator via email at
        info@modernangles.com
      </li>
      <li>
        <b>Request Restriction of or Object to</b> our processing of your personal information when
        processing is noncompliant.
      </li>
      <li>
        <b>Withdraw your Consent</b> to our processing of your personal information. If you refrain
        from providing personal information or withdraw your consent to processing, some
        features of our Service may not be available.
      </li>
      <li>
        <b>Request data portability</b> and receive an electronic copy of personal information that you
        have provided to us.
      </li>
      <li>
        <b>Be informed</b> about third parties with which your personal information has been shared.
      </li>
      <li>
        <b>Request the review of decisions</b> taken exclusively based on automated processing if that
        could affect data subject rights.
      </li>
    </ul>

    <p>
      We will use commercially reasonable efforts to honor your requests for deletion; however,
      certain information will actively persist on the Service even if you close your account, including
      any messages you send within our App. In addition, the rights described above may be limited,
      for example, if fulfilling your request would reveal personal information about another person, or
      if you ask us to delete information we are required by law to keep or have compelling legitimate
      interests in keeping (such as for fraud prevention purposes). Your Personal Information may
      remain in our archives and information you update or delete, or information within a closed
      account, may persist internally for our administrative purposes, to the extent permitted by law. In
      addition, we typically will not remove information you posted publicly through or on the
      Service. Bear in mind that neither you nor Modern Angles can delete all copies of information
      that has been previously shared with others on the Service.
    </p>

    <h2>
      7. SECURITY
    </h2>

    <p>
      We take steps to ensure that your information is treated securely and in accordance with
      this Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant
      the security of any information you provide to us. We have taken appropriate safeguards to
      require that your Personal Information will remain protected and require our third-party service
      providers and partners to have appropriate safeguards as well. To the fullest extent permitted by
      applicable law, we do not accept liability for unauthorized disclosure.
    </p>
    <p>
      By using our Service or providing Personal Information to us, you agree that we may
      communicate with you electronically regarding security, privacy, and administrative issues
      relating to your use of our Service. If we learn of a security system’s breach, we may attempt to
      notify you electronically by posting a notice on our Service, by mail or by sending an email to
      you.
    </p>

    <h2>
      8. CALIFORNIA PRIVACY RIGHTS
    </h2>

    <p>
      California Civil Code Section 1798.83 permits users of the Service that are California residents
      to request certain information regarding our disclosure of personal information to third parties
      for their direct marketing purposes. To make such a request, please send an email
      to info@modernangles.com.
    </p>

    <h3>
      California Consumer Protection Act (“CCPA”)
    </h3>

    <p>
      If you are a resident of the state of California, you may have certain data protection rights that
      can be exercised either by emailing us at info@modernangles.com or mailing us at:
      info@modernangles.com.
    </p>

    <p>
      Modern Angles LTD.
      <br/>
      16192 Coastal Highway
      <br/>
      Lewes, Delaware 19958
      <br/>
      info@modernangles.com
    </p>

    <p>
      Your rights under the CCPA may include the following:
    </p>

    <ul>
      <li>
        The right to access or delete the information we have on you. Whenever made possible,
        you can access or request deletion of your personal data.
      </li>
      <li>
        The right to know what personal information is collected, used, shared or sold as to the
        categories and specific pieces of personal information, and the sources of that
        information, and types of information we collect, and the intended purposes of that
        information, and the parties with whom we share it.
      </li>
      <li>The right to opt-out of sale of personal information.</li>
      <li>The right to non-discrimination in terms of price or service when you exercise a privacy
        right under the CCPA. Exercising these rights may impact your right or ability to receive
        information about our products and the Services, and the benefits of the Services.
      </li>
    </ul>

    <h2>
      9. INTERNATIONAL TRANSFER OF PERSONAL INFORMATION
    </h2>

    <p>
      Currently, the Service is intended to be accessed and provided within the United States.
      However, all information processed by us may be transferred, processed, and stored anywhere in
      the world, including, but not limited to, the United States or other countries, which may have
      data protection laws that are different from the laws where you live. We endeavor to safeguard
      your information consistent with the requirements of applicable laws. If you are a European
      resident, you have the right to access personal information we hold about you and to ask that
      your personal information be corrected, updated, or deleted. If you would like to exercise this
      right, please contact us through the contact information below. Additionally, if you are a
      European resident, we note that we are processing your information in order to fulfill contracts
      we might have with you (for example if you make an order through the Website), or otherwise to
      pursue our legitimate business interests listed above. Please note that your information will be
      transferred outside of Europe, including to the United States.
    </p>

    <h2>
      10. LINKS TO OTHER SITES
    </h2>

    <p>
      Our Service contains links to other websites. If you choose to click on a third party link, you
      will be directed to that third party’s website. The fact that we link to a website is not an
      endorsement, authorization or representation of our affiliation with that third party, nor is it an
      endorsement of their privacy or information security policies or practices. We do not exercise
      control over third party websites. These other websites may place their own cookies or other files
      on your computer, collect data or solicit Personal Information from you. We encourage you to
      read the privacy policies or statements of the other websites you visit.
    </p>

    <h2>
      11. PHISHING
    </h2>

    <p>
      Phishing websites imitate legitimate websites in order to obtain personal or financial
      information. Identity theft and the practice currently known as “phishing” are of great concern to
      Modern Angles. For more information about phishing, visit the website of the Federal Trade
      Commission at <a href="https://www.consumer.ftc.gov/articles/0003-phishing">https://www.consumer.ftc.gov/articles/0003-phishing</a>. In addition, if you believe
      you have received an email or had a similar interaction with a third party pretending to be
      Modern Angles, please report it at info@modernangles.com.
    </p>

    <h2>
      12. CHANGES TO THIS POLICY
    </h2>

    <p>
      Modern Angles may update this Privacy Policy at any time and any changes will be effective
      upon posting. In the event that there are substantial changes to the way we treat your Personal
      Information, we will display a notice through the Services prior to the change becoming
      effective. We may also notify you by email, in our discretion. However, we will use your
      Personal Information in a manner consistent with the Privacy Policy in effect at the time you
      submitted the information, unless you consent to the new or revised policy. Your continued use
      of the Service after we make changes is deemed to be acceptance of those changes, so please
      check this privacy policy periodically for updates.
    </p>

    <h2>
      13. CONTACT US
    </h2>

    <p>
      If you have any questions about our privacy practices or this Privacy Policy, or to exercise
      your rights as detailed in this Privacy Policy, please contact us at:
      <br />
      <br />
      Modern Angles LTD.
      <br />
      16192 Coastal Highway
      <br />
      Lewes, Delaware 19958
      <br />
      info@modernangles.com
    </p>
  </PrivacyNoticeWrapper>
};

export default PrivacyNoticeSection;
