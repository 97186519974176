import React from "react"
import {withRouter} from "react-router-dom"
import PropertyTourEditPhotos from "./property_tour_edit_photos"
import noImagePlaceholderSrc from '../../../../assets/images/no-image.jpeg'
import PageHeader from "../page_header"
import {fetchProject, fetchProjectMedias} from "../../util/project_api_util";
import PropertyTourEditExternalPhotos from "./property_tour_edit_external_photos";


class PropertyTourEditMain extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      project: null,
      modelLink: "",
      videoLink: "",
      description: "",
      property_mls_id: "",
      beds: "",
      baths: "",
      price: "",
      square_feet: "",
      lot_size: "",
      cover: "",
      cover_src: "",
      hidden_medias: {},
      sorted_medias: {},
      changed_medias: {},
      propertyErrors: [],
      loading: false
    }

    this.handler = this.handler.bind(this)
    this.fetchMedias = this.fetchMedias.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleCoverSelected = this.handleCoverSelected.bind(this)
    this.updatePropertyTour = this.updatePropertyTour.bind(this)
  }

  componentDidMount() {
    const projectId = this.props.match.params.id
    fetchProject(projectId).then(response => {
      const project = Object.values(response)[0]
      this.fetchMedias(project?.current_service?.id)
      this.setState({
        project,
        property_mls_id: project.property_mls_id || "",
        videoLink: project.external_video_id || "",
        modelLink: project.external_model_id || "",
        description: project.description || "",
        beds: project.beds || "",
        baths: project.baths || "",
        price: project.price || "",
        square_feet: project.square_feet || "",
        cover_src: project.property_tour_cover || "",
        lot_size: project.lot_size || ""
      })
    })
    window.scrollTo(0, 0)
  }

  updateProjectExternalPhotos(project) {
    this.state.project.external_photos = project.external_photos
    this.setState({project: {...this.state.project}})
  }

  fetchMedias(service_id) {
    const projectId = this.props.match.params.id
    return fetchProjectMedias(projectId, service_id).then((response) => {
      const projectMedias = Object.values(response)[0]
      this.setState({project: {...this.state.project, ...projectMedias}})
    })
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.cover_src)
  }

  handleCoverSelected(event) {
    let cover = event.target.files[0]
    let cover_src = URL.createObjectURL(cover)

    this.setState({
      cover: cover,
      cover_src: cover_src
    })
  }

  handleInput(field) {
    return e => this.setState({[field]: e.currentTarget.value})
  }

  handler(newState) {
    this.setState(newState)
  }

  updatePropertyTour() {
    let formData = new FormData()
    formData.append("project[external_model_id]", this.state.modelLink)
    formData.append("project[external_video_id]", this.state.videoLink)
    formData.append("id", this.state.project.id)

    const fields = ['description', 'baths', 'beds', 'square_feet', 'price', 'property_mls_id', 'lot_size']
    for (const field of fields) {
      formData.append(`project[${field}]`, this.state[field])
    }

    if (this.state.cover !== "") {
      formData.append("project[cover]", this.state.cover)
    }

    this.setState({loading: true})

    this.props.updatePropertyTour(formData).then(() => {
      let sorted = Object.values(this.state.sorted_medias)

      let all_sorted_medias = {}

      sorted.forEach((sorted_group) => {
        sorted_group.forEach((media, index) => {
          all_sorted_medias[media.id] = index
        })
      })

      this.props.sortMedia(all_sorted_medias)
    }).then(() => {
      this.props.toggleHiddenMedia(Object.values(this.state.changed_medias))
    }).then(() => {
      this.showSavingLabel()
      this.setState({loading: false})
    }).catch(() => {
      this.setState({loading: false})
    })
  }

  showSavingLabel() {
    this.props.showToast("Listing website changes have been saved")
  }

  render() {
    const {project} = this.state
    if (!project) return <div/>

    const projectAddress = [
      project.address?.street1, project.address?.city, project.address?.region, project.address?.zip_code
    ].filter(v => !!v).join(', ')

    return (
      <div className="dashboardMainDiv">
        <PageHeader title={project.address.formatted_address ? `${project.address.apartment_number || ""} ${project.address.formatted_address}` : projectAddress} back="Back to Listing Website page" backLink={`/${project.slug}/listing-website`}/>

        <div className="editProjectSection d-block">
          <div className="propertyTourSectionBody">
            <h5 className="propertyTourSectionTitle">Media & Gallery</h5>
            <PropertyTourEditPhotos changed_medias={this.state.changed_medias}
                                    sorted_medias={this.state.sorted_medias} hidden_medias={this.state.hidden_medias}
                                    isLargeScreen={this.props.isLargeScreen} project={project}
                                    fetchMedias={this.fetchMedias} changeParentState={this.handler}/>
          </div>

          <div className="propertyTourSectionBody">
            <h5 className="propertyTourSectionTitle">Custom photos upload</h5>
            <PropertyTourEditExternalPhotos isLargeScreen={this.props.isLargeScreen}
                                            project={project}
                                            showToast={msg => this.props.showToast(msg)}
                                            onReload={project => this.updateProjectExternalPhotos(project)}/>
          </div>

          <div className="propertyTourSectionBody">
            <h5 className="propertyTourSectionTitle">Cover Image</h5>
            <div className="propertyTourEditMediaSection">
              <img className="propertyTourEditCoverImage" src={this.state.cover_src}/>
              <div className="propertyTourEditMediaSectionInput">
                <label htmlFor="coverImg" className="propertyTourEditButton">
                  Upload file
                  <input type="file" className="d-none" id="coverImg" accept="image/*"
                         onChange={this.handleCoverSelected}/>
                </label>
              </div>
            </div>
          </div>

          <div className="propertyTourSectionBody">
            <h5 className="propertyTourSectionTitle">3D model iframe</h5>
            <div className="propertyTourEditMediaSection">
              {this.state.modelLink ?
                <iframe className="propertyTourEditCoverImage" src={this.state.modelLink}/> :
                <img src={noImagePlaceholderSrc} className="propertyTourEditCoverImage"/>}

              <div className="propertyTourEditMediaSectionInput floatingLabelsForm">
                <div className="inputGroup w-100">
                  <label>Model Link</label>
                  <input className="formInput w-100" placeholder="Content"
                         onChange={this.handleInput("modelLink")}
                         value={this.state.modelLink}/>
                </div>
              </div>
            </div>
          </div>

          <div className="propertyTourSectionBody">
            <h5 className="propertyTourSectionTitle">Video iframe</h5>
            <div className="propertyTourEditMediaSection">
              {this.state.videoLink ? (
                  <video className="propertyTourEditCoverImage" controls>
                    <source src={this.state.videoLink} type="video/mp4"/>
                  </video>) :
                <img src={noImagePlaceholderSrc} className="propertyTourEditCoverImage"/>
              }
              <div className="propertyTourEditMediaSectionInput floatingLabelsForm">
                <div className="inputGroup w-100">
                  <label>Video Link</label>
                  <input className="formInput w-100" placeholder="https://youtube.com/123456789"
                         onChange={this.handleInput("videoLink")}
                         value={this.state.videoLink}/>
                </div>
              </div>
            </div>
          </div>

          <div className="propertyTourSectionBody">
            <h5 className="propertyTourSectionTitle">Property Information</h5>
          </div>

          <div className="propertyTourSectionBody">
            <div className="floatingLabelsForm mt-2">
              <div className="row">
                <div className="col-12">
                  <div className="inputGroup">
                    <label className="required">Property Description</label>
                    <textarea className="formInput" placeholder="Content" onChange={this.handleInput("description")}
                              value={this.state.description} style={{minHeight: '85px'}}/>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="inputGroup">
                    <label className="required">Asking Price</label>
                    <input className="formInput" placeholder="$345,678" onChange={this.handleInput("price")}
                           value={this.state.price ? parseInt(this.state.price) : ""} type="number"/>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="inputGroup">
                    <label>MLS ID</label>
                    <input className="formInput" placeholder="Content" onChange={this.handleInput("property_mls_id")}
                           value={this.state.property_mls_id}/>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-3 col-md-6">
                  <div className="inputGroup">
                    <label>Number of beds</label>
                    <input className="formInput" placeholder="Content" onChange={this.handleInput("beds")}
                           value={this.state.beds} type="number"/>
                  </div>
                </div>
                <div className="col-12 col-lg-3 col-md-6">
                  <div className="inputGroup">
                    <label>Number of baths</label>
                    <input className="formInput" placeholder="Content" onChange={this.handleInput("baths")}
                           value={this.state.baths} type="number"/>
                  </div>
                </div>

                <div className="col-12 col-lg-3 col-md-6">
                  <div className="inputGroup">
                    <label>Square feet</label>
                    <input className="formInput" placeholder="Content" onChange={this.handleInput("square_feet")}
                           value={this.state.square_feet} type="number"/>
                  </div>
                </div>
                <div className="col-12 col-lg-3 col-md-6">
                  <div className="inputGroup">
                    <label>Lot Size (in acres)</label>
                    <input className="formInput" placeholder="Content" step="0.01" onChange={this.handleInput("lot_size")}
                           value={this.state.lot_size} type="number"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="propertyTourSectionFooter">
            <div className="d-flex align-items-center">
              <button className="propertyTourEditButton" onClick={this.updatePropertyTour} disabled={this.state.loading}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(PropertyTourEditMain)