import React from "react"
import {Link} from "react-router-dom"
import logoImage from "../../../../assets/images/logo-new-dark.svg"
import twitterIcon from "../../../../assets/images/icons/twitter-2.svg"
import linkedinIcon from "../../../../assets/images/icons/linkedin-2.svg"
import copyLinkIcon from "../../../../assets/images/icons/copy-link.svg"
import instagramIcon from "../../../../assets/images/icons/instagram-2.svg"
import facebookIcon from "../../../../assets/images/icons/facebook-2.svg"
import mobileMenuIcon from "../../../../assets/images/landing/header-menu.svg"
import mobileMenuLogo from "../../../../assets/images/landing/navbar/mobile-logout-logo.svg"
import mobileMenuCloseIcon from "../../../../assets/images/landing/navbar/mobile-logout-cross.svg"

import {connect} from "react-redux";
import {showToast} from "../../actions/toast_actions";
import {Collapse, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {ArrowIcon} from "../navbar/styles/MobileLoggedOutNavbarStyles";
import {NAVBAR_MOBILE} from "../../../../assets/images/navbar";

class PropertyTourNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedText: null,
      mobileMenuOpened: false,
      copyUrlDropdownOpened: false,
      isShareDropdownOpened: false
    }

    this.openMobileMenu = this.openMobileMenu.bind(this)
    this.closeMobileMenu = this.closeMobileMenu.bind(this)
  }

  copyUrl(e, url) {
    e.preventDefault()
    navigator.clipboard.writeText(`${window.location.origin}${url}`).then(() => {
      this.setCopiedText('URL has been copied successfully');
    }, () => {
      this.setCopiedText('Could not copy URL');
    })
  }

  copyUnbrandedUrl(e, slug) {
    e.preventDefault();

    // Construct the tour subdomain URL
    const hostname = window.location.host;
    const protocol = window.location.protocol;

    // Remove 'www' if it exists in the hostname
    const baseHost = hostname.startsWith('www.') ? hostname.slice(4) : hostname;
    const tourHost = `tour.${baseHost}`;
    const unbrandedUrl = `${protocol}//${tourHost}/${slug}/listing-website`;

    // Copy the tour URL to the clipboard
    navigator.clipboard.writeText(unbrandedUrl).then(
      () => {
        this.setCopiedText('URL has been copied successfully');
      },
      () => {
        this.setCopiedText('Could not copy URL');
      }
    );
  }

  setCopiedText(text) {
    this.props.showToast(text);
  }

  openMobileMenu(e) {
    e.preventDefault()
    this.setState({mobileMenuOpened: true})
  }

  closeMobileMenu(e) {
    this.setState({mobileMenuOpened: false})
  }

  onToggleShareDropdown(e) {
    e.preventDefault()
    this.setState({isShareDropdownOpened: !this.state.isShareDropdownOpened})
  }

  render() {
    const {has3DTour, hasGallery, hasFloorplan, currentUser, project, branded} = this.props
    const {isShareDropdownOpened} = this.state
    const canEdit = (project?.client_id && (project.client_id === currentUser?.id || currentUser?.roles?.admin))

    const menus = [
      {
        href: "#details",
        title: "Details",
        show: true
      },
      {
        href: "#3d-tour",
        title: "3D Tour",
        show: has3DTour
      },
      {
        href: "#gallery",
        title: "Gallery",
        show: hasGallery
      },
      {
        href: "#floorplan",
        title: "Floorplan",
        show: hasFloorplan
      },
      {
        href: "#contact",
        title: "Contact",
        show: branded
      },
      {
        href: "#map",
        title: "Map",
        show: true
      },
    ].filter(i => i.show)

    const instagramLink = "https://www.instagram.com";
    const linkedinLink = "https://www.linkedin.com/sharing/share-offsite/?url=" + escape(location.href);
    const twitterLink = "https://twitter.com/intent/tweet?url=" + escape(location.href);
    const facebookLink = "https://www.facebook.com/sharer/sharer.php?u=" + escape(location.href);

    return <>
      <div className="navbar-container">
        <div>
          {branded && <>
            <div className="reactNavPoweredBy">Powered by:</div>
            <Link to="/">
              <img className="reactNavLogoImagePropertyTour" src={logoImage}/>
            </Link>
          </>}
        </div>

        <div className="property-tour-navbar-desktop">
          <div className="navMenu">
            {menus.map(item => (
              <a href={item.href} key={`property-tour-navbar-${item.href}`}>{item.title}</a>
            ))}
          </div>

          {canEdit && <div className="shareButtonGroupContainer editButtonGroupContainer">
            <div className="shareButtonGroup">
              <div className="shareLabel">
                <a className="editButtonLink" href={`/${project?.slug}/listing-website/edit`}>Edit</a>
              </div>
            </div>
          </div>}

          <div className="shareButtonGroupContainer">
            <div className="shareButtonGroup">
              <div className="shareLabel">
                Share:
              </div>

              <a href={linkedinLink} target="_blank">
                <img src={linkedinIcon}></img>
              </a>
              <a href={twitterLink} data-size="large"
                 target="_blank">
                <img src={twitterIcon}></img>
              </a>
              <a href={facebookLink} data-size="large"
                 target="_blank">
                <img src={facebookIcon}></img>
              </a>
              <Dropdown onMouseOver={() => this.setState({copyUrlDropdownOpened: true})}
                        onMouseLeave={() => this.setState({copyUrlDropdownOpened: false})}
                        toggle={() => this.setState({copyUrlDropdownOpened: !this.state.copyUrlDropdownOpened})}
                        isOpen={this.state.copyUrlDropdownOpened}
                        setActiveFromChild={true}>
                <DropdownToggle nav>
                  <img src={copyLinkIcon}/>
                </DropdownToggle>

                <DropdownMenu>
                  <div className="dropdown-menu-inner">
                    <a onClick={e => this.copyUrl(e, `/${project?.slug}/listing-website`)} href="#">
                      Branded
                    </a>

                    <a onClick={e => this.copyUnbrandedUrl(e, project?.slug)}
                      href="#" className="nav-link">
                      Unbranded
                    </a>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="property-tour-navbar-mobile flex-column flex-row ms-2">
          <div className="property-tour-navbar-mobile-icon">
            <img src={mobileMenuIcon} onClick={this.openMobileMenu}/>
          </div>
        </div>

        <div className={`property-tour-navbar-mobile-menu ${this.state.mobileMenuOpened ? 'show' : ''}`}>
          <div className="property-tour-navbar-mobile-menu-logo">
            <img src={mobileMenuLogo}/>
            <div className="close-icon" onClick={this.closeMobileMenu}>
              <img src={mobileMenuCloseIcon}/>
            </div>
          </div>

          <div className="property-tour-navbar-mobile-menu-items">
            <ul className="navbar-nav">
              {menus.map(item => (
                <li className="nav-item" key={`property-tour-navbar-${item.href}`}>
                  <a href={item.href} className="nav-link" onClick={this.closeMobileMenu}>{item.title}</a>
                </li>
              ))}

              {canEdit && <li className="nav-item">
                <a href={`/${project?.slug}/listing-website/edit`}
                   className="nav-link" onClick={this.closeMobileMenu}>
                  Edit
                </a>
              </li>}

              <li className="nav-item">
                <a href="#" className="nav-link" onClick={e => this.onToggleShareDropdown(e)}>
                  Share

                  <ArrowIcon className={`${isShareDropdownOpened ? 'arrowIcon' : ''}`}
                             src={NAVBAR_MOBILE.NavBarMobileArrow} alt=''/>
                </a>
              </li>

              <Collapse isOpen={isShareDropdownOpened} tag="li">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a onClick={e => this.copyUrl(e, `/projects_index/${project?.slug}/listing-website`)}
                       href="#" className="nav-link">
                      Branded
                    </a>
                  </li>
                  <li className="nav-item">
                    <a onClick={e => this.copyUnbrandedUrl(e, project?.slug)}
                       href="#" className="nav-link">
                      Unbranded
                    </a>
                  </li>
                </ul>
              </Collapse>
            </ul>
          </div>
        </div>
      </div>
    </>
  }
}

const msp = () => ({})
const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
})

export default connect(msp, mdp)(PropertyTourNavbar)